<template>
  <div class="points">
    <!-- <count-to
      :start-val="0"
      :end-val="pointsData.totlePointCount"
      :duration="1000"
      class="card-panel-num"
    /> -->
  </div>
</template>

<script>
import CountTo from "vue-count-to";
import { pointsUrl, pointsList, getPoints } from "./api.js";
import { getHashParam } from "@/utils/utils.js";
var moment = require("moment");
export default {
  name: "points",
  data() {
    return {
      form: {
        donatePic: "",
        donateContent: "",
      },
      pointsData: {
        totlePointCount: 0,
      },
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {
    this.getPoints();
  },
  methods: {
    toList() {
      this.$router.push({
        name: "pointsList",
      });
    },

    getPoints() {
      let params = {
        userId: this.userId,
        tenantId: this.tenantId,
      };
      this.$axios
        .post(`${pointsUrl}`, this.$qs.stringify(params), {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((res) => {
          if (res.code === 200) {
            if (res.data) {
              this.pointsData = res.data;
            }
          } else {
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.points {
  min-height: 100vh;
  background: #f5f5f5;
}
</style>
<style lang="less">
.points {
}
</style>
